import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import AboutHeader from './AboutHeader';
import AboutCareers from './AboutCareers';
import AboutTeam from './AboutTeam';
import AboutPartners from './AboutPartners';
import { getMessages } from '../../locales';

const About = () => {
  return (
    <div className="about-page">
      <AboutHeader />
      <AboutTeam />
      <AboutCareers />
      <AboutPartners />
    </div>
  );
};

export default About;
