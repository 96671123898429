import React from 'react';
import { array } from 'prop-types';
import { getMessages } from '../../locales';
import Quotes from '../common/Quotes/Quotes';

const AboutTeam = () => {
  const messages = getMessages();

  return (
    <section className="about-team">
      <div className="container">
        <h3 className="about-team-title title-lg">{messages['about.team.title']}</h3>
        <Quotes quotes={messages['team.quotes']} />
      </div>
    </section>
  );
};

AboutTeam.propTypes = {
  team: array.isRequired,
};

export default AboutTeam;
